exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-featured-js": () => import("./../../../src/pages/featured.js" /* webpackChunkName: "component---src-pages-featured-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaotica-js": () => import("./../../../src/pages/kaotica.js" /* webpackChunkName: "component---src-pages-kaotica-js" */),
  "component---src-pages-reel-js": () => import("./../../../src/pages/reel.js" /* webpackChunkName: "component---src-pages-reel-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

